<template>
    <modal ref="modalCrearFeature" titulo="Crear Feature Flag" tamano="modal-lg" no-aceptar adicional="Guardar" @adicional="postFlags">
        <ValidationObserver ref="validacion">
            <div class="row mx-0 px-5 mb-4 justify-center">
                <div class="col-6">
                    <p class="f-15 text-muted2 f-600 pl-3">Nombre</p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:60" name="Nombre">
                        <el-input v-model="model.nombre" placeholder="Nombre Feature" maxlength="60" show-word-limit class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <p class="f-15 text-muted2 f-600 pl-3">Nombre sin espacios</p>
                    <ValidationProvider v-slot="{errors}" rules="required|max:60|espacios" name="Nombre sin espacios">
                        <el-input v-model="model.flag" placeholder="Nombre sin espacios" maxlength="60" show-word-limit class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span><br />
                        <span v-if="!flagUnique" class="text-danger w-100 f-11">Este nombre ya pertenece a otro Feature Flag</span>
                    </ValidationProvider>
                </div>
                <div class="col-12 my-3">
                    <p class="f-15 text-muted2 f-600 pl-3">Descripción</p>
                    <ValidationProvider v-slot="{errors}" rules="max:300" name="Descripción">
                        <el-input v-model="model.descripcion" placeholder="Descripción" maxlength="300" show-word-limit class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-12">
                    <p class="f-15 text-muted2 f-600 pl-3">Texto de apoyo</p>
                    <ValidationProvider v-slot="{errors}" rules="max:120" name="Texto de apoyo">
                        <el-input v-model="model.texto" placeholder="Texto de apoyo" maxlength="120" show-word-limit class="w-100" />
                        <span class="text-danger w-100 f-11"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-12 d-flex justify-center mt-4">
                    <div class="col-auto">
                        <el-radio v-model="model.estado" class="radio-orange" :label="1">
                            Enable
                        </el-radio>
                    </div>
                    <div class="col-auto">
                        <el-radio v-model="model.estado" class="radio-orange" :label="2">
                            Full Disable
                        </el-radio>
                    </div>
                    <div class="col-auto">
                        <el-radio v-model="model.estado" class="radio-orange" :label="3">
                            Disable + Text
                        </el-radio>
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Service from '~/services/faqs/faqs';
import { extend } from 'vee-validate'

extend('espacios', {
    validate(value){
        var result = /^\S*$/u.test(value);
        return result;
    },
    params: ['length'],
    message: 'EL campo {_field_} no puede llevar espacios en blanco.'
});
export default {
    data(){
        return {
            flagUnique: true,
            model: {
                nombre: null,
                flag: null,
                descripcion: null,
                texto: null,
                estado: 1,
                idCarpeta: null,
            }
        }
    },
    methods: {
        async postFlags(){
            try {
                const valid = await this.$refs.validacion.validate()
                if(!valid)return;
                const {data} = await Service.postFlags(this.model);
                if(!data.exito){
                    return this.flagUnique = false;
                }
                this.notificacion('Mensaje', data.mensaje, data.tipo);
                this.$emit('nuevo');
                this.$refs.modalCrearFeature.toggle();
            } catch(error){
                this.error_catch(error)
            }
        },
        limpiar(){
            this.$refs.validacion.reset();
            this.flagUnique = true;
            this.model.nombre = null;
            this.model.flag = null;
            this.model.descripcion = null;
            this.model.texto = null;
            this.model.estado = 1;
            this.model.idCarpeta = null;
        },
        toggle(id){
            this.limpiar();
            this.model.idCarpeta = id;
            this.$refs.modalCrearFeature.toggle();
        }
    }
}
</script>